import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Hashtag } from "../../constants/hashtags.constants";
import { ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { AlexanderBoydDisplaysImagesQuery } from "../../types/generated";

export const ProjectMetaInfoAlexanderBoydDisplays: ProjectMetaInfo = {
  name: "alexander-boyd-displays",
  displayName: "Alexander Boyd Displays",
  abbreviation: "ABD",
  subtitle: "Website & Animation advertisement",
  // TODO
  description: "",
  categories: [
    Category.design,
    Category.websites,
  ],
  sectors: [
  ],
  hashtags: [
    Hashtag.animations,
  ],
  // hasPage: false,
  hasPage: true,
  keywords: [
    'Alexander Boyd Displays',
    'animation design',
    'web design',
    'motion graphics',
  ],
}
export const useAlexanderBoydDisplaysInfo = () => {
  const AlexanderBoydDisplaysImages: AlexanderBoydDisplaysImagesQuery = useStaticQuery(graphql`
    query AlexanderBoydDisplaysImages {
      featuredImage: file(relativePath: {eq: "images/alexander-boyd-displays/alexander-boyd-displays-video-screenshot-cinema.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
    }
  `)
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoAlexanderBoydDisplays,
    featuredImage: {
      image: AlexanderBoydDisplaysImages.featuredImage!.childImageSharp,
      title: 'Alexander Boyd Display Advertisement Video Preview',
      alt: 'Alexander Boyd Display Advertisement Video Preview Image showing a man putting up outdoors posters on the exterior wall of a vintage cinema',
    },
  }
  return info;
}