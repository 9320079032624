import React from 'react';
import CTAButton from '../../components/CTAButton/CTAButton';
import ProjectPage from '../../components/ProjectPage/ProjectPage';
import YouTubeEmbed from '../../components/YouTubeEmbed/YouTubeEmbed';
// import { useAAAIDPAnimationInfo } from '../../content/projects/aaa-idp-animation.project';
import { useCPDAnimationInfo } from '../../content/projects/cpd-animation.project';
import { useAlexanderBoydDisplaysInfo } from '../../content/projects/alexander-boyd-displays.project';
import { PageComponent } from '../../types/gatsby.types';
// import './ProjectPageJimOCallaghan.scss';

type ProjectPageAlexanderBoydAnimationProps = {}

const ProjectPageAlexanderBoydAnimation: PageComponent<ProjectPageAlexanderBoydAnimationProps> = props => {
  const metaInfo = useAlexanderBoydDisplaysInfo();
  return <ProjectPage
    {...props} // always include
    project={metaInfo}
    sidebarExtraContent={
      <CTAButton to="https://www.youtube.com/watch?v=QVdJdPWuSTI" title="Watch on YouTube" target="_blank" ribbons drawLine="left">Watch on YouTube</CTAButton>
    }
  >
    <YouTubeEmbed
      src="https://www.youtube.com/embed/QVdJdPWuSTI"
      title="CPD Video Player"
    />
  </ProjectPage>
}

export default ProjectPageAlexanderBoydAnimation;